/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
//  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
// @import '~bootstrap/scss/functions';
$fa-font-path: "~font-awesome/fonts/";
@import 'variables';
@import '~bootstrap/scss/bootstrap';
// @import '~glyphicons-halflings/scss/glyphicons-halflings.scss';
@import '~font-awesome/scss/font-awesome';

@import '~primereact/resources/themes/lara-light-indigo/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';

// @import '~line-awesome/dist/line-awesome/css/line-awesome.css';
// @import '../fonts/flaticon/flaticon';
// @import '~animate.css/animate';
// @import '~awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
// @import '~rickshaw/rickshaw.css';
// @import '~react-toastify/dist/ReactToastify.css';

@import 'mixins';
@import 'base';
@import 'auth';
// @import 'overrides';
@import 'general';
// @import 'utils';

