.root {
  height: calc(100vh - 120px);
  overflow: hidden;
  .wrapper {
    height: 100%;
    .widgetBodyClass {
      height: 100%;
      overflow: auto;
      background-color: #fff;
    }
  }
}