@import '../../styles/app';

.root {
  height: calc(100vh - 120px);
  overflow: hidden;

  .wrapper {
    height: 100%;

    .widgetBodyClass {
      height: 100%;
      overflow: auto;
      background-color: #fff;
    }
  }
}

.connectionItem:hover {
  background-color: #4b7d8133;
}

.icebergicon, .duckdbicon{
  width: 160px;
}

.dbheading{
  font-size: 14px;
  font-weight: bold;
}

.radiolabel{
  margin-left: 10px;
}

.ml_20 {
  margin-left: 20px;
  margin-bottom: 15px;
}