.root {
    height: calc(100vh - 160px);
  
    .wrapper {
      .widgetBodyClass {
        height: calc(100vh - 190px);
        background-color: #fff;
        overflow: auto;
      }
    }
  }